/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap');
@import 'colorVariables';
@import 'functions';
@import 'variables';
@import 'mixins';
@import 'root';
@import 'reboot';
@import 'type';
@import 'images';
@import 'code';
@import 'grid';
@import 'container-grid';
@import 'tables';
@import 'forms';
@import 'buttons';
@import 'transitions';
@import 'dropdown';
@import 'button-group';
@import 'input-group';
@import 'custom-forms';
@import 'nav';
@import 'navbar';
@import 'card';
@import 'breadcrumb';
@import 'pagination';
@import 'badge';
@import 'jumbotron';
@import 'alert';
@import 'progress';
@import 'media';
@import 'list-group';
@import 'close';
@import 'toasts';
@import 'modal';
@import 'tooltip';
@import 'popover';
@import 'carousel';
@import 'spinners';
@import 'utilities';
@import 'print';

// a:hover {
//   text-decoration: none;
// }

@include media-breakpoint-down(sm) {
  .hero-image-placeholder {
    width: 100%;
    // height: 410px;
  }

  .hero-image-placeholder img:nth-child(2) {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  .container-grid {
    .item.column-7,
    .item.column-5,
    .item.column-4,
    .item.column-3 {
      grid-column-end: span 12;
    }
  }
  .testimonial-hero,
  .testimonial-hero img {
    width: 100%;
  }

  .testimonial-hero img:nth-child(2) {
    top: -10px;
    left: -10px;
    z-index: 1;
  }

  .card-booking {
    padding: 32px;
  }
}

@include media-breakpoint-up(sm) {
  .hero-image-placeholder {
    width: 520px;
    height: 410px;
  }

  .testimonial-hero,
  .testimonial-hero img {
    width: 365px;
    height: 500px;
    position: absolute;
  }

  .card-booking {
    padding: 60px 80px;
  }
}

.hero-image-placeholder {
  position: relative;
}

.brand-text-icon {
  font-size: 26px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  &.hover {
    color: $primary;
    text-decoration: none;
  }
}

header {
  border-bottom: 1px solid $gray-200;
  width: 100%;
  position: relative;
  margin-bottom: 50px;

  .navbar {
    height: 80px;

    ul.navbar-nav {
      li.nav-item {
        &.active {
          a.nav-link {
            color: $primary;
          }
        }
        a {
          &.nav-link {
            letter-spacing: 1px;
            color: $gray-900;
            padding-left: 1rem;
            padding-right: 1rem;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

section {
  margin-bottom: 70px;
}

.img-cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.img-contain {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.container-grid {
  .item {
    div {
      height: 100%;
    }
  }
  .tag {
    max-height: 30px;
  }
}

.card {
  &.bordered {
    border: 1px solid $gray-200;
  }
  border-radius: 15px;

  .tag {
    z-index: 3;
    font-size: 0.875rem;
    background-color: $pink;
    padding: 0.25rem 1.25rem;
    position: absolute;
    top: 0;
    right: 0;
    color: $white;
    min-height: 30px;
    border-radius: 0 15px;
    font-weight: 400;
    span {
      font-weight: 300;
    }
  }

  .img-wrapper {
    height: 100%;
    margin-bottom: 0;
    border-radius: 0.9375rem;
    overflow: hidden;
  }

  .meta-wrapper {
    margin-top: 1rem;
    h5 {
      font-size: 1.125rem;
      margin-bottom: 2px;
    }
    span {
      font-size: 0.875rem;
    }
  }

  &.card-featured {
    overflow: hidden;
    cursor: pointer;
    height: 100%;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(1.1);
      transition: 0.5s transform ease-in-out;
      z-index: 2;
      @include gradient-y(rgba($black, 0), rgba($black, 0.51), 0%, 100%);
    }

    .img-wrapper {
      z-index: 1;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(1.1);
      transition: 0.25s transform ease-in-out;
    }

    .meta-wrapper {
      z-index: 4;
      color: $white;
      padding: 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      transform: translateY(0);
      transition: 0.3s transform ease-in-out;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
    }

    &:hover {
      .meta-wrapper {
        transform: translateY(-10px);
      }
      &:after {
        transform: scale(1);
      }
      .img-wrapper {
        transform: scale(1);
      }
    }
  }
}

.testimonial-hero {
  position: relative;
}

footer {
  border-top: 1px solid $gray-200;
  padding-top: 50px;

  .brand-tagline {
    margin-top: 8px;
    color: $gray-500;
    font-weight: 300;
  }

  .list-group-item {
    padding: 0.3125rem 0;
    border: 0;
    a,
    span {
      color: $gray-500;
      font-weight: 300;
    }
    a {
      @include hover-focus {
        color: $gray-800;
      }
    }
  }
  .copyrights {
    margin: 50px 0;
    font-weight: 300;
    color: $gray-500;
  }
}

main {
  p {
    color: $gray-500;
    font-weight: 300;
  }
}
