@import "../../../assets/scss/colorVariables";

.input-text {
  margin-bottom: 20px;
  position: relative;
  .input-group {
    background-color: $white;
    .input-group-text {
      pointer-events: none;
      border: 0;
      cursor: auto;
      padding: 0;
      border-radius: 4px !important;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      background-color: $gray-900;
      position: absolute;
      z-index: 999;
    }

    .form-control {
      border: 0;
      border-radius: 4px;
      outline: 0;
      box-shadow: none;
      background-color: $gray-100;
      padding: 0.575px 14px;
      width: 100%;
      height: 45px;
      &::placeholder {
        color: $gray-200;
      }
    }
  }

  .error-helper {
    position: absolute;
    top: 45px;
    background-color: $red;
    color: $white;
    padding: 0 2px;
    font-size: 0.675px;
  }
}
