@import "../../../assets/scss/colorVariables";

.input-date {
  .input-group {
    background-color: $white;

    .input-group-text {
      pointer-events: none;
      border: 0;
      cursor: pointer;
      padding: 0;
      border-radius: 4px !important;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      background-color: $gray-900;
      position: absolute;
      z-index: 999;
    }

    .form-control {
      border: 0;
      border-radius: 0;
      outline: 0;
      box-shadow: none;
      background-color: $gray-100;
      padding: 0.575rem 45px;
      text-align: center;
      justify-content: center;
      width: 100px;
      height: 45px;
    }

    .date-range-wrapper {
      padding: px;
      background-color: $white;
      box-shadow: 0 0 20px rgba($color: $black, $alpha: 0.1);
      position: absolute;
      border-radius: 4px;
      top: 45px;
      z-index: 5;
    }
  }
}
