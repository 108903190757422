@import "../../assets/scss/colorVariables";

.stars {
  position: relative;
  min-width: 200px;
  display: inline-flex;
  .star {
    position: absolute;
    display: inline-flex;
    mask: url(../../assets/images/icons/star.svg) no-repeat center left;
    &:not(.placeholder) {
      z-index: 2;
      background-color: $yellow;
    }
    &.placeholder {
      z-index: 1;
      background-color: $gray-200;
    }
  }
}
